@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: transparent;
    overflow: hidden;
}

.background span {
    width: 1vmin;
    height: 1vmin;
    border-radius: 1vmin;
    backface-visibility: hidden;
    position: absolute;
    animation: move;
    animation-duration: 1;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}


.background span:nth-child(0) {
    color: #5d00ff;
    top: 57%;
    left: 45%;
    animation-duration: 335s;
    animation-delay: -473s;
    transform-origin: 23vw -10vh;
    box-shadow: 2vmin 0 1.2168776150909402vmin currentColor;
}
.background span:nth-child(1) {
    color: #201f2d;
    top: 81%;
    left: 56%;
    animation-duration: 482s;
    animation-delay: -342s;
    transform-origin: 21vw -19vh;
    box-shadow: -2vmin 0 0.9783728475003934vmin currentColor;
}
.background span:nth-child(2) {
    color: #201f2d;
    top: 2%;
    left: 83%;
    animation-duration: 381s;
    animation-delay: -44s;
    transform-origin: 9vw -8vh;
    box-shadow: 2vmin 0 1.0545429229831327vmin currentColor;
}
.background span:nth-child(3) {
    color: #201f2d;
    top: 75%;
    left: 19%;
    animation-duration: 388s;
    animation-delay: -125s;
    transform-origin: -8vw -16vh;
    box-shadow: 2vmin 0 0.28217356980868136vmin currentColor;
}
.background span:nth-child(4) {
    color: #ffffff;
    top: 73%;
    left: 90%;
    animation-duration: 69s;
    animation-delay: -132s;
    transform-origin: -5vw 5vh;
    box-shadow: -2vmin 0 0.4240883073958419vmin currentColor;
}
.background span:nth-child(5) {
    color: #5d00ff;
    top: 48%;
    left: 42%;
    animation-duration: 58s;
    animation-delay: -167s;
    transform-origin: -2vw -5vh;
    box-shadow: -2vmin 0 0.8501140536247054vmin currentColor;
}
.background span:nth-child(6) {
    color: #201f2d;
    top: 59%;
    left: 54%;
    animation-duration: 385s;
    animation-delay: -421s;
    transform-origin: -10vw -2vh;
    box-shadow: 2vmin 0 0.4679939819326029vmin currentColor;
}
.background span:nth-child(7) {
    color: #201f2d;
    top: 87%;
    left: 90%;
    animation-duration: 220s;
    animation-delay: -415s;
    transform-origin: 3vw -18vh;
    box-shadow: 2vmin 0 0.9713194463783799vmin currentColor;
}
.background span:nth-child(8) {
    color: #ffffff;
    top: 62%;
    left: 51%;
    animation-duration: 273s;
    animation-delay: -93s;
    transform-origin: 3vw 23vh;
    box-shadow: 2vmin 0 0.29439144672182826vmin currentColor;
}
.background span:nth-child(9) {
    color: #5d00ff;
    top: 73%;
    left: 56%;
    animation-duration: 292s;
    animation-delay: -164s;
    transform-origin: -24vw 10vh;
    box-shadow: -2vmin 0 0.9685135016056161vmin currentColor;
}
.background span:nth-child(10) {
    color: #5d00ff;
    top: 12%;
    left: 35%;
    animation-duration: 370s;
    animation-delay: -255s;
    transform-origin: -22vw 10vh;
    box-shadow: -2vmin 0 0.862724438334397vmin currentColor;
}
.background span:nth-child(11) {
    color: #ffffff;
    top: 44%;
    left: 82%;
    animation-duration: 318s;
    animation-delay: -36s;
    transform-origin: -24vw -22vh;
    box-shadow: -2vmin 0 1.2025033215757943vmin currentColor;
}
.background span:nth-child(12) {
    color: #5d00ff;
    top: 72%;
    left: 24%;
    animation-duration: 422s;
    animation-delay: -64s;
    transform-origin: 8vw 0vh;
    box-shadow: 2vmin 0 1.071895604923565vmin currentColor;
}
.background span:nth-child(13) {
    color: #201f2d;
    top: 14%;
    left: 43%;
    animation-duration: 15s;
    animation-delay: -326s;
    transform-origin: 24vw -23vh;
    box-shadow: 2vmin 0 0.9416445806806137vmin currentColor;
}
.background span:nth-child(14) {
    color: #ffffff;
    top: 42%;
    left: 16%;
    animation-duration: 330s;
    animation-delay: -75s;
    transform-origin: -13vw 2vh;
    box-shadow: 2vmin 0 1.2380287979569797vmin currentColor;
}
.background span:nth-child(15) {
    color: #ffffff;
    top: 39%;
    left: 35%;
    animation-duration: 235s;
    animation-delay: -367s;
    transform-origin: -23vw 3vh;
    box-shadow: 2vmin 0 0.667547099257565vmin currentColor;
}
.background span:nth-child(16) {
    color: #201f2d;
    top: 10%;
    left: 41%;
    animation-duration: 385s;
    animation-delay: -226s;
    transform-origin: -21vw 5vh;
    box-shadow: -2vmin 0 1.0984023000505907vmin currentColor;
}
.background span:nth-child(17) {
    color: #201f2d;
    top: 56%;
    left: 19%;
    animation-duration: 36s;
    animation-delay: -486s;
    transform-origin: 13vw 12vh;
    box-shadow: 2vmin 0 0.3457714442844837vmin currentColor;
}
.background span:nth-child(18) {
    color: #ffffff;
    top: 61%;
    left: 67%;
    animation-duration: 76s;
    animation-delay: -290s;
    transform-origin: -16vw 1vh;
    box-shadow: -2vmin 0 1.2404641316944545vmin currentColor;
}
.background span:nth-child(19) {
    color: #201f2d;
    top: 36%;
    left: 87%;
    animation-duration: 467s;
    animation-delay: -27s;
    transform-origin: -23vw 16vh;
    box-shadow: -2vmin 0 1.218938493781959vmin currentColor;
}
.background span:nth-child(20) {
    color: #ffffff;
    top: 69%;
    left: 88%;
    animation-duration: 287s;
    animation-delay: -374s;
    transform-origin: -16vw 2vh;
    box-shadow: -2vmin 0 0.5374832042860131vmin currentColor;
}
.background span:nth-child(21) {
    color: #201f2d;
    top: 67%;
    left: 83%;
    animation-duration: 428s;
    animation-delay: -117s;
    transform-origin: -11vw 11vh;
    box-shadow: -2vmin 0 0.979302202660949vmin currentColor;
}
.background span:nth-child(22) {
    color: #5d00ff;
    top: 42%;
    left: 96%;
    animation-duration: 485s;
    animation-delay: -482s;
    transform-origin: 19vw 24vh;
    box-shadow: -2vmin 0 0.6712916269331026vmin currentColor;
}
.background span:nth-child(23) {
    color: #ffffff;
    top: 23%;
    left: 100%;
    animation-duration: 157s;
    animation-delay: -104s;
    transform-origin: 8vw -21vh;
    box-shadow: 2vmin 0 0.6336810402440558vmin currentColor;
}
.background span:nth-child(24) {
    color: #ffffff;
    top: 45%;
    left: 93%;
    animation-duration: 139s;
    animation-delay: -214s;
    transform-origin: 15vw -15vh;
    box-shadow: 2vmin 0 1.0084978610226847vmin currentColor;
}
.background span:nth-child(25) {
    color: #ffffff;
    top: 71%;
    left: 5%;
    animation-duration: 189s;
    animation-delay: -322s;
    transform-origin: -12vw -11vh;
    box-shadow: -2vmin 0 0.9345910156716644vmin currentColor;
}
.background span:nth-child(26) {
    color: #ffffff;
    top: 36%;
    left: 13%;
    animation-duration: 454s;
    animation-delay: -473s;
    transform-origin: -3vw -19vh;
    box-shadow: 2vmin 0 0.5739617498911462vmin currentColor;
}
.background span:nth-child(27) {
    color: #ffffff;
    top: 73%;
    left: 20%;
    animation-duration: 68s;
    animation-delay: -39s;
    transform-origin: 23vw -6vh;
    box-shadow: 2vmin 0 0.36663967964420285vmin currentColor;
}
.background span:nth-child(28) {
    color: #ffffff;
    top: 53%;
    left: 83%;
    animation-duration: 208s;
    animation-delay: -142s;
    transform-origin: -6vw -8vh;
    box-shadow: -2vmin 0 0.5477677892549324vmin currentColor;
}
.background span:nth-child(29) {
    color: #ffffff;
    top: 54%;
    left: 37%;
    animation-duration: 8s;
    animation-delay: -249s;
    transform-origin: 13vw -17vh;
    box-shadow: -2vmin 0 0.681923691203324vmin currentColor;
}
.background span:nth-child(30) {
    color: #201f2d;
    top: 45%;
    left: 80%;
    animation-duration: 309s;
    animation-delay: -226s;
    transform-origin: -22vw 13vh;
    box-shadow: -2vmin 0 1.0292127174390489vmin currentColor;
}
.background span:nth-child(31) {
    color: #ffffff;
    top: 77%;
    left: 34%;
    animation-duration: 465s;
    animation-delay: -243s;
    transform-origin: 0vw -12vh;
    box-shadow: -2vmin 0 1.2130983824879014vmin currentColor;
}
.background span:nth-child(32) {
    color: #5d00ff;
    top: 27%;
    left: 93%;
    animation-duration: 60s;
    animation-delay: -138s;
    transform-origin: -3vw 12vh;
    box-shadow: -2vmin 0 0.9630693648920257vmin currentColor;
}
.background span:nth-child(33) {
    color: #ffffff;
    top: 71%;
    left: 84%;
    animation-duration: 398s;
    animation-delay: -66s;
    transform-origin: 23vw 6vh;
    box-shadow: 2vmin 0 0.6737470645407433vmin currentColor;
}
.background span:nth-child(34) {
    color: #ffffff;
    top: 15%;
    left: 48%;
    animation-duration: 414s;
    animation-delay: -23s;
    transform-origin: -19vw 23vh;
    box-shadow: 2vmin 0 1.1497483311422874vmin currentColor;
}
.background span:nth-child(35) {
    color: #ffffff;
    top: 17%;
    left: 18%;
    animation-duration: 461s;
    animation-delay: -451s;
    transform-origin: 22vw -15vh;
    box-shadow: 2vmin 0 0.8449505895261222vmin currentColor;
}
.background span:nth-child(36) {
    color: #ffffff;
    top: 93%;
    left: 50%;
    animation-duration: 262s;
    animation-delay: -315s;
    transform-origin: 9vw -6vh;
    box-shadow: -2vmin 0 0.4170116029627329vmin currentColor;
}
.background span:nth-child(37) {
    color: #ffffff;
    top: 20%;
    left: 83%;
    animation-duration: 24s;
    animation-delay: -16s;
    transform-origin: 16vw -12vh;
    box-shadow: -2vmin 0 1.1493446133154122vmin currentColor;
}
.background span:nth-child(38) {
    color: #ffffff;
    top: 73%;
    left: 95%;
    animation-duration: 375s;
    animation-delay: -174s;
    transform-origin: -11vw -9vh;
    box-shadow: 2vmin 0 1.1595134595286136vmin currentColor;
}
.background span:nth-child(39) {
    color: #5d00ff;
    top: 51%;
    left: 48%;
    animation-duration: 473s;
    animation-delay: -297s;
    transform-origin: 11vw -16vh;
    box-shadow: -2vmin 0 0.8824669410009696vmin currentColor;
}
.background span:nth-child(40) {
    color: #201f2d;
    top: 59%;
    left: 35%;
    animation-duration: 466s;
    animation-delay: -314s;
    transform-origin: -12vw 17vh;
    box-shadow: -2vmin 0 0.32583010003877044vmin currentColor;
}
.background span:nth-child(41) {
    color: #5d00ff;
    top: 96%;
    left: 37%;
    animation-duration: 189s;
    animation-delay: -454s;
    transform-origin: 2vw 1vh;
    box-shadow: -2vmin 0 0.6862197396288705vmin currentColor;
}
.background span:nth-child(42) {
    color: #5d00ff;
    top: 65%;
    left: 3%;
    animation-duration: 342s;
    animation-delay: -361s;
    transform-origin: 9vw -12vh;
    box-shadow: 2vmin 0 0.7524525014232211vmin currentColor;
}
.background span:nth-child(43) {
    color: #ffffff;
    top: 7%;
    left: 74%;
    animation-duration: 186s;
    animation-delay: -391s;
    transform-origin: -9vw 25vh;
    box-shadow: 2vmin 0 0.5080544868455146vmin currentColor;
}
.background span:nth-child(44) {
    color: #ffffff;
    top: 31%;
    left: 68%;
    animation-duration: 376s;
    animation-delay: -271s;
    transform-origin: 12vw -16vh;
    box-shadow: -2vmin 0 0.2756491403048147vmin currentColor;
}
.background span:nth-child(45) {
    color: #201f2d;
    top: 60%;
    left: 44%;
    animation-duration: 242s;
    animation-delay: -40s;
    transform-origin: -12vw 15vh;
    box-shadow: 2vmin 0 1.0351417344430398vmin currentColor;
}
.background span:nth-child(46) {
    color: #ffffff;
    top: 49%;
    left: 63%;
    animation-duration: 338s;
    animation-delay: -444s;
    transform-origin: 19vw 10vh;
    box-shadow: 2vmin 0 0.7544859576134759vmin currentColor;
}
.background span:nth-child(47) {
    color: #ffffff;
    top: 95%;
    left: 8%;
    animation-duration: 142s;
    animation-delay: -362s;
    transform-origin: -19vw 3vh;
    box-shadow: 2vmin 0 0.2757520186374469vmin currentColor;
}
.background span:nth-child(48) {
    color: #201f2d;
    top: 48%;
    left: 99%;
    animation-duration: 367s;
    animation-delay: -187s;
    transform-origin: 1vw 23vh;
    box-shadow: -2vmin 0 0.9762073521272978vmin currentColor;
}
.background span:nth-child(49) {
    color: #201f2d;
    top: 18%;
    left: 29%;
    animation-duration: 38s;
    animation-delay: -410s;
    transform-origin: -2vw -19vh;
    box-shadow: -2vmin 0 0.8546757650479324vmin currentColor;
}
